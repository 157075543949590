define("discourse/plugins/discourse-ai/discourse/components/ai-llm-editor", ["exports", "@glimmer/component", "@ember/object", "discourse/components/back-button", "discourse/plugins/discourse-ai/discourse/components/ai-llm-editor-form", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _backButton, _aiLlmEditorForm, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiLlmEditor extends _component.default {
    constructor() {
      super(...arguments);
      if (this.args.llmTemplate) {
        this.configurePreset();
      }
    }
    configurePreset() {
      let [id1, model1] = this.args.llmTemplate.split(/-(.*)/);
      if (id1 === "none") {
        return;
      }
      const info1 = this.args.llms.resultSetMeta.presets.findBy("id", id1);
      const modelInfo1 = info1.models.findBy("name", model1);
      this.args.model.setProperties({
        max_prompt_tokens: modelInfo1.tokens,
        tokenizer: info1.tokenizer,
        url: modelInfo1.endpoint || info1.endpoint,
        display_name: modelInfo1.display_name,
        name: modelInfo1.name,
        provider: info1.provider
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "configurePreset", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <BackButton
          @route="adminPlugins.show.discourse-ai-llms"
          @label="discourse_ai.llms.back"
        />
        <AiLlmEditorForm @model={{@model}} @llms={{@llms}} />
      
    */
    {
      "id": "p6PO9Fum",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@route\",\"@label\"],[\"adminPlugins.show.discourse-ai-llms\",\"discourse_ai.llms.back\"]],null],[1,\"\\n    \"],[8,[32,1],null,[[\"@model\",\"@llms\"],[[30,1],[30,2]]],null],[1,\"\\n  \"]],[\"@model\",\"@llms\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-llm-editor.js",
      "scope": () => [_backButton.default, _aiLlmEditorForm.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiLlmEditor;
});